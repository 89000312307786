import React, { useContext } from 'react'
import './Services.css'
import HeartEmoji from "../../img/heartemoji.png"
import Glasses from "../../img/glasses.png"
import Humble from "../../img/humble.png"
import Resume from './resume.pdf'
import Card from '../Card/Card'
import { themeContext } from "../../Context";
import { motion } from 'framer-motion';


function Services() {

    const transition = {duration:1,type:'spring'};
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;

  return (
    <div className="services" id="services">
        {/* left side */}
        <div className="awesome">
            <span style={{ color: darkMode ? "white" : "" }}>My Awesome</span>
            <span>services</span>
            <span style={{ color: darkMode ? "white" : "" }}> Lorem ispum is simpley dummy text of printing of printing Lorem <br /> ispum is simpley dummy text of printing</span>
            <a href={Resume} download>
                <button className="button s-button">Download csv</button>
            </a>
            <div className="blur s-blur1" style={{ background: "#ABF1FF94" }}></div>
        </div>
        {/* right side */}
        <div className="cards">
            <motion.div
            initial={{left:'20rem'}}
            whileInView={{left:'14rem'}}
            transition={transition}
            style={{left:'14rem'}}>
                <Card emoji={HeartEmoji} heading={"Design"} detail={"Figma, Sketch, Photoshop, Adobe Illustrator, Adobe xd"}/>
            </motion.div>
            <motion.div
            initial={{top:'12rem',left:'-10rem'}}
            whileInView={{top:'12rem',left:'-4rem'}}
            transition={transition}
             style={{top:'12rem',left:'-4rem'}}>
                <Card emoji={Glasses} heading={"Developer"} detail={"Html, Css, JavaScript, React, Nodejs, Express"}/>
            </motion.div>
            <motion.div
            initial={{top:"19rem",left:'18rem'}}
            whileInView={{top:'19rem',left:'12rem'}}
            transition={transition}
            style={{top:'19rem',left:'12rem'}}>
            <Card emoji={Humble} heading={"UI/UX"} detail={"Lorem ispum dummy text are usually use in section where we need some random text"} color="rgba(252, 166, 31, 0.45)"/>
            </motion.div>
            
            <div className="blur s-blur2" style={{ background: "var(--purple)" }}></div>
        </div>
    </div>
  )
}

export default Services